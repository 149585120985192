import React from 'react'

import WysiwygPage from '../components/Templates/WysiwygPage'
import { graphql } from 'gatsby'
import { extract } from '../utils/graphql'
// import mergePreviewData from '../utils/mergePreviewData'
import { withPreview } from 'gatsby-source-prismic'

const ContentPage = ({ data, pageContext }) => {
  const { prismicTechnologySubPage } = data
  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(prismicTechnologySubPage),
    mantelData: extract.mantel(prismicTechnologySubPage, { isThick: true }),
    content: prismicTechnologySubPage.data.content.html,
  }
  return <WysiwygPage {...hydratedData} />
}

export default withPreview(ContentPage)

export const pageQuery = graphql`
  query TechSubPageBySlug($uid: String!, $locale: String!) {
    prismicTechnologySubPage(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        content {
          html
        }
        cta_button_text {
          text
        }
        cta_button_link {
          link_type
          url
        }
      }
    }
  }
`
