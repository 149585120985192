import React from 'react'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import Mantel from '../../General/Mantel'
import WysiwygContent from '../../General/WysiwygContent'

const Faq = ({ seo, mantelData, content, children }) => (
  <PageWrap bg={3} seo={seo}>
    <Mantel {...mantelData} />
    <div className="max-w-xl mx-auto py-8">
      <WysiwygContent content={content} />
      {children}
    </div>
  </PageWrap>
)

export default Faq
